<style scoped>
.c-progress-button {
  opacity: unset !important;
  cursor: not-allowed;
}
@media (max-width: 1024px) {
  .c-sm-spacer {
    margin-top: 1rem;
  }
}
.c-wait {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  cursor: wait;
}
</style>
<script>

import {get_acsrf_token} from "@/methods";

let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import CopyableText from "@/components/CopyableText";
import {HalfCircleSpinner} from "epic-spinners";
import {mapGetters} from "vuex";
import {maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
  props: ['plan'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
	  HalfCircleSpinner,
    checkout_overview: () => import('@/components/lazy/billing/CheckoutOverview'),
    checkout_summary: () => import('@/components/lazy/billing/CheckoutSummary'),
    checkout_payment_method: () => import('@/components/lazy/billing/CheckoutPaymentMethods'),
    checkout_billing_address: () => import('@/components/lazy/billing/CheckoutBillingAddress'),
  },
  validations: {

  },
  computed: {
    ...mapGetters([
      'getAccountName',
      'getAccountId',
    ])
  },
  methods: {
    stepButtonClass(frame) {
      if(frame === this.frame) return 'btn-outline-primary';
      if(this.frames[frame] === true) return 'btn-outline-success';
      return 'btn-outline-dark';
    },
    stepTextClass(frame) {
      if(frame === this.frame) return '';
      if(this.frames[frame] === true) return 'text-success';
      return 'text-muted';
    },
    async onFrameReady() {
      this.frameLoading = false;
    },
    async onFrameCompleted() {
      this.frameLoading = true;
      this.frames[this.frame] = true;

      let nextFrame = null;
      this.steps.forEach((step) => {
        if(this.frames[step] === false && !nextFrame) {
          nextFrame = step;
        }
      });

      if(nextFrame !== null) {
        this.frame = nextFrame;
        this.$nextTick(() => {
          this.frameLoading = false;
        });
      } else {
        await this.completeCheckout();
      }
    },
    async processSubscriptionSetupResponse(setupResponse) {
      if(setupResponse.status) {
        document.body.classList.toggle("fullpage-overlay-enabled");
        this.$emit('hideCheckout');
        this.$swal({
          icon: 'success',
          title: this.$t('billing.checkout.completed.title'),
          text: this.$t('billing.checkout.completed.message')
        });
      } else {
        document.body.classList.toggle("fullpage-overlay-enabled");
        if(setupResponse.error === 'billing') {
          this.frames['payment_method'] = false;
          this.frames['summary'] = false;
          this.frame = 'payment_method';

          if (setupResponse.message) {
            this.$swal({
              icon: 'error',
              title: setupResponse.message,
              text: this.$t('billing.checkout.denied.message')
            });
          } else {
            this.$swal({
              icon: 'error',
              title: this.$t('billing.checkout.denied.title'),
              text: this.$t('billing.checkout.denied.message')
            });
          }
        } else {
          this.$swal({
            icon: 'error',
            text: this.$t('error.server.generic.message')
          });
        }
      }
    },
    async prepareCheckout() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/store/subscription/omega/checkout?plan=${this.plan}`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();

          this.planInfo = data.plan;
          if(data.checkout.is_change) this.planInfo.change = data.checkout.change;
          else this.planInfo.change = null;

          this.steps = data.checkout.steps;
          this.frame = this.steps[0];

          if(!this.steps.includes('billing_address')) this.frames.billing_address = true;
          if(!this.steps.includes('payment_method')) this.frames.payment_method = true;

          this.ready = true;
        } else {
          this.error = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async completeCheckout() {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          plan: this.plan,
          confirm: true
        };

        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/store/subscription/omega/checkout`, {
          method: this.planInfo.change === null ? 'POST' : 'PUT',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          document.body.classList.toggle("fullpage-overlay-enabled");
        } else {
          if(response.status === 451) {
            this.$swal({
              icon: 'error',
              title: this.$t('billing.checkout.errors.embargo.title'),
              text: this.$t('billing.checkout.errors.embargo.message')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.frameLoading = false;
    }
  },
  created() {
    try {
      this.$socket.client.on('billing:subscription:setup', this.processSubscriptionSetupResponse);
    } catch (e) {
      // nothing
    }
  },
  async mounted() {
    await this.prepareCheckout();
  },
  destroyed() {
    try {
      this.$socket.client.off('billing:subscription:setup', this.processSubscriptionSetupResponse);
    } catch (e) {
      // nothing
    }
  },
  data() {
    return {
      ready: false,
      error: false,
      planInfo: null,
      steps: ['overview', 'billing_address', 'payment_method', 'summary'],
      frame: 'overview',
      frames: {
        overview: false,
        billing_address: false,
        payment_method: false,
        summary: false
      },
      frameLoading: false
    }
  }
};
</script>

<template>
  <div>
    <div class="fullpage-overlay c-wait">
      <div class="fullpage-overlay-content">
        <h3 class="text-white">
          <half-circle-spinner
              :animation-duration="2000"
              :size="80"
              color="white"
              class="ml-auto mr-auto"
          />
        </h3>
        <div class="row justify-content-center">
          <h3 class="text-muted">
            {{ $t('billing.checkout.wait') }}
          </h3>
        </div>
      </div>
    </div>

    <template v-if="ready">
      <div class="row justify-content-center">
        <div class="col-lg-2 col-sm-12" v-if="steps.includes('overview')">
          <button class="c-progress-button btn btn-block" disabled="disabled" :class="stepButtonClass('overview')">
            <h5 class="text-uppercase mb-0" :class="stepTextClass('overview')">
              <i class="fad fa-shopping-cart"/>
              {{ $t('billing.checkout.overview.title') }}
            </h5>
          </button>
        </div>
        <div class="col-lg-2 col-sm-12 c-sm-spacer" v-if="steps.includes('billing_address')">
          <button class="c-progress-button btn btn-block" disabled="disabled" :class="stepButtonClass('billing_address')">
            <h5 class="text-uppercase mb-0" :class="stepTextClass('billing_address')">
              <i class="fad fa-address-book"/>
              {{ $t('billing.checkout.billing_address.title') }}
            </h5>
          </button>
        </div>
        <div class="col-lg-2 col-sm-12 c-sm-spacer" v-if="steps.includes('payment_method')">
          <button class="c-progress-button btn btn-block" disabled="disabled" :class="stepButtonClass('payment_method')">
            <h5 class="text-uppercase mb-0" :class="stepTextClass('payment_method')">
              <i class="fad fa-money-check"/>
              {{ $t('billing.checkout.payment_method.title') }}
            </h5>
          </button>
        </div>
        <div class="col-lg-2 col-sm-12 c-sm-spacer" v-if="steps.includes('summary')">
          <button class="c-progress-button btn btn-block" disabled="disabled" :class="stepButtonClass('summary')">
            <h5 class="text-uppercase mb-0" :class="stepTextClass('summary')">
              <i class="fad fa-cash-register"/>
              {{ $t('billing.checkout.summary.title') }}
            </h5>
          </button>
        </div>
      </div>
      <div class="row mt-4 justify-content-center">
        <div class="col-lg-10 col-sm-12">
          <template v-if="!frame || frameLoading">
            <div class="w-100">
              <half-circle-spinner
                  :animation-duration="2000"
                  :size="80"
                  color="white"
                  class="ml-auto mr-auto"
              />
            </div>
          </template>
          <template v-else>
            <component v-if="ready" v-bind:is="'checkout_'+frame" :isCheckout="true" :plan="plan" :planInfo="planInfo" @frameReady="onFrameReady" @frameCompleted="onFrameCompleted"></component>
          </template>
        </div>
      </div>
      <div class="row justify-content-center mb-4">
        <div class="col-lg-2 col-sm-12">
          <div class="d-inline-block">
            <h4 class="mb-0 font-size-18">
              <button class="btn btn-outline-danger" v-on:click="$emit('cancelCheckout')">
                <i class="fas fa-backspace"></i> Cancel Checkout
              </button>
            </h4>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 c-sm-spacer"></div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div style="margin: auto; width: 80px;">
        <half-circle-spinner
            :animation-duration="2000"
            :size="80"
            color="white"
            class="align-middle"
        />
      </div>
    </template>
  </div>
</template>
